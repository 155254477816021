export * from './analytics/actionTypes'
export * from './taskList/actionTypes'
//------------------------------------MUTATIONS--------------------------------------
export const SET_HIGHLIGHTED_ROW_AFTER_ACTION = 'SET_HIGHLIGHTED_ROW_AFTER_ACTION'
export const HIGHLIGHT_ROW_AFTER_ACTION = 'HIGHLIGHT_ROW_AFTER_ACTION'
export const INIT = 'INIT'
//--Audience
export const SET_IS_AUDIENCE_EDIT_DIALOG = 'SET_IS_AUDIENCE_EDIT_DIALOG'
export const ADD_AUDIENCE_DATA = 'ADD_AUDIENCE_DATA'
export const UPDATE_ONE_AUDIENCE = 'UPDATE_ONE_AUDIENCE'
export const ADD_ONE_AUDIENCE = 'ADD_ONE_AUDIENCE'
export const DELETE_ONE_AUDIENCE = 'DELETE_ONE_AUDIENCE'
export const DELETE_ONE_FILTER_FROM_AUDIENCE_SETTINGS = 'DELETE_ONE_FILTER_FROM_AUDIENCE_SETTINGS'
//--Messages
export const ADD_MESSAGES_DATA = 'ADD_MESSAGES_DATA'
export const ADD_OR_UPDATE_ONE_MESSAGE = 'ADD_OR_UPDATE_ONE_MESSAGE'
export const DELETE_ONE_MESSAGE = 'DELETE_ONE_MESSAGE'
export const SET_IS_MESSAGE_EDIT_NAME_DIALOG = 'SET_IS_MESSAGE_EDIT_NAME_DIALOG'
//--Activations
export const ADD_ACTIVATION_DATA = 'ADD_ACTIVATION_DATA'
export const UPDATE_ONE_ACTIVATION = 'UPDATE_ONE_ACTIVATION'
export const ADD_ONE_ACTIVATION = 'ADD_ONE_ACTIVATION'
export const SET_IS_ACTIVATION_EDIT_DIALOG = 'SET_IS_ACTIVATION_EDIT_DIALOG'
export const DELETE_ONE_ACTIVATION = 'DELETE_ONE_ACTIVATION'
//------------------------------------ACTIONS--------------------------------------
//--Audience
export const FETCH_AND_ADD_AUDIENCE_DATA = 'FETCH_AND_ADD_AUDIENCE_DATA'
//--Messages
export const FETCH_AND_ADD_MESSAGE_DATA = 'FETCH_AND_ADD_MESSAGE_DATA'
//--Activation
export const FETCH_AND_ADD_ACTIVATION_DATA = 'FETCH_AND_ADD_ACTIVATION_DATA'
//------------------------------------GETTERS--------------------------------------
//--Audience
export const GET_AUDIENCE_DATA = 'GET_AUDIENCE_DATA'
export const GET_AUDIENCE_GROUPS = 'GET_AUDIENCE_GROUPS'
export const GET_ONE_AUDIENCE = 'GET_ONE_AUDIENCE'
//--Messages
export const GET_MESSAGE_DATA = 'GET_MESSAGE_DATA'
export const GET_MESSAGE_GROUPS = 'GET_MESSAGE_GROUPS'
export const GET_ONE_MESSAGE = 'GET_ONE_MESSAGE'
export const GET_MAX_MESSAGE_PART_NUMBER = 'GET_MAX_MESSAGE_PART_NUMBER'
//--Activation
export const GET_ACTIVATION_DATA = 'GET_ACTIVATION_DATA'
export const GET_ACTIVATION_GROUPS = 'GET_ACTIVATION_GROUPS'
export const GET_ONE_ACTIVATION = 'GET_ONE_ACTIVATION'
export const GET_RUNNING_ACTIVATIONS_COUNT = 'GET_RUNNING_ACTIVATIONS_COUNT'
export const GET_ACTIVATIONS_COUNT_LIMIT = 'GET_ACTIVATIONS_COUNT_LIMIT'
