
import { PropType, computed, defineComponent, onMounted, ref, onBeforeMount } from 'vue'
import * as _ from 'lodash'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
import useRouter from '../../../../../hooks/useRouter'
import FileUploader from '../../../../ui/FileUploader.vue'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { AdvancedMessageEditor, FileUploader },
    setup(props, { emit }) {
        console.log(props)
        const { route } = useRouter()

        const logFile = ev => {
            console.log(ev)
        }
        return {
            isUndefined: _.isUndefined,
            logFile,
        }
    },
    computed: {
        isActivation(): any {
            const currentURL = this.$router.currentRoute.fullPath
            return currentURL.includes('/GCB2/activation')
        },
    },
})
