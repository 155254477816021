//Автоопределение buildType
const buildType =
    window.location.hostname === 'localhost'
        ? 'LOCAL'
        : window.location.hostname === 'app.dataheroes.pro'
        ? 'PRODUCTION'
        : 'TEST'

//const buildType = process.env.VUE_APP_BUILD_TYPE
const repostStaffId = buildType === 'LOCAL' ? 8 : buildType === 'PRODUCTION' ? 51 : 8
const apiEndpoint =
    buildType === 'LOCAL'
        ? 'http://localhost:3000/api'
        : buildType === 'PRODUCTION'
        ? 'https://bff.dataheroes.pro/api'
        : 'https://bff.crmmsk.ru/api'
const chatEndpoint =
    buildType === 'LOCAL'
        ? 'http://localhost:5173'
        : buildType === 'PRODUCTION'
        ? 'https://dh-chat.dataheroes.pro'
        : 'https://test-dh-chat.dataheroes.pro'
const updaterApiEndpoint =
    buildType === 'LOCAL'
        ? 'http://localhost:3000/api'
        : buildType === 'PRODUCTION'
        ? 'https://update.dataheroes.pro/api'
        : 'https://update.crmmsk.ru/api'
const authApiEndpoint =
    buildType === 'LOCAL'
        ? 'http://localhost:3001/api'
        : buildType === 'PRODUCTION'
        ? 'https://bff.dataheroes.pro/api'
        : 'https://bff.crmmsk.ru/api'
export default {
    repostStaffId,
    buildType,
    apiEndpoint,
    chatEndpoint,
    updaterApiEndpoint,
    mqUrl: getMqUrl(),
    authApiEndpoint,
    adminApiEndopint: 'admin-backend.dataheroes.pro',
    amoCrmClientId:
        buildType === 'PRODUCTION'
            ? '3af37da7-bfbc-4a15-a4f0-29d757ad66b0'
            : 'be342937-0271-45c2-8507-dca06616c56a',
    zmClientId: buildType !== 'LOCAL' ? 'g3ed2ca22a9c6e0546b04e0b95b379' : 'gccc81de8d34384de0fc8c78c75d7e',
    zmConsumerKey: buildType !== 'LOCAL' ? 'f5e28444ee' : 'ca0a5f6d87',
    zmRedirectUri:
        buildType !== 'LOCAL' ? 'https://app.crmmsk.ru/OAuthLogin' : 'http://localhost:8080/OAuthLogin',
    disabledConnectorsTypes:
        buildType === 'PRODUCTION' ? ['alfa', 'planfact', 'zenmoney'] : ['alfa', 'planfact', 'zenmoney'],
    disabledDashboardsIds: buildType === 'PRODUCTION' ? ['outflow', 'attendance'] : ['outflow', 'attendance'],
    disabledSettings: { yClients: ['group', 'division'] },
    yClientSimpleAuth: true, //Упрощенная авторизация yClients
    firebaseCfg: getFbConfig(buildType),
    idleReloadTimeout: 60, //Minutes
    wsUrl: getWsUrl(),
    audienceWithReturnAfterVar: buildType === 'PRODUCTION' ? [708] : [3931],
    adminsWhitelist: [
        'auth0|YO1yEueLQu71OtyaSMMXF',
        'google-oauth2|112947983865517618451',
        'google-oauth2|106448966005337154243',
        'google-oauth2|111747802967162964419',
        'google-oauth2|112331075346786769506',
        'google-oauth2|115772460460441863064',
        'auth0|61b8e0ba9397e6006af6e557',
        'auth0|KXOuvv47jMzQaP0NMXG8o',
        'google-oauth2|104152221588238449234',
        'auth0|-vC_3_IHpkYuK9UDp91xn',
        'google-oauth2|116265841278486246844',
        'google-oauth2|106212038968126676728',
        'auth0|PUQCormh3Ki3Y_c5-jRmo',
        'google-oauth2|103654676938763064226',
        'auth0|_KomYIgegHln9AShSzlde',
        'auth0|TSdF8ldOhnLJvyimYU0TT',
        'auth0|pZwaFF5u-wXxLtGVodj4d',
        'google-oauth2|102027286731767220713',
        'auth0|uoY16A1cgLHMiJHdO4S1U',
        'auth0|LZKTFm-eZdO-vQ_bJXSmv',
        'auth0|Pr-yAqoGybmWixlvOsM8-',
        'google-oauth2|110335629880405454179',
        'auth0|5Dt0L0Wou67C0yBgSUMAQ',
        'auth0|mQX7liSLMcVw2I4HHtRop',
        'auth0|pEFC2yuwuSI5HFaxL1uyr',
        'auth0|ksfV0e2kSUwAwnvqVTsKv',
        'google-oauth2|105973164533268684809',
        'auth0|GYd1E3gxwksxzA-EIErd6',
    ],
}

function getFbConfig(buildType) {
    const test = {
        apiKey: 'AIzaSyBh49z5PEySttHukit52Ix7Aoi7zYuDoso',
        authDomain: 'crmmsk.firebaseapp.com',
        databaseURL: 'https://crmmsk.firebaseio.com',
        projectId: 'crmmsk',
        storageBucket: 'crmmsk.appspot.com',
        messagingSenderId: '1082960155805',
        appId: '1:1082960155805:web:0f93e4e73cd4edc84d7074',
    }
    const prod = {
        apiKey: 'AIzaSyDT7Uk6xaMp-uzordXtpmUL5E_nFhoAfZQ',
        authDomain: 'dataheroes-prod.firebaseapp.com',
        databaseURL: 'https://dataheroes-prod-default-rtdb.firebaseio.com',
        projectId: 'dataheroes-prod',
        storageBucket: 'dataheroes-prod.appspot.com',
        messagingSenderId: '197795024512',
        appId: '1:197795024512:web:0b1f217667036a61a7e065',
    }
    if (buildType === 'LOCAL') {
        return test
    }
    if (buildType === 'TEST') {
        return test
    }
    if (buildType === 'PRODUCTION') {
        return prod
    }
}
function getWsUrl() {
    if (buildType === 'PRODUCTION') {
        return 'https://ws.dataheroes.pro/'
    } else {
        return 'https://ws.crmmsk.ru/'
    }
}
function getMqUrl() {
    if (buildType === 'PRODUCTION') {
        return 'https://mq.dataheroes.pro'
    } else {
        return 'https://mq.crmmsk.ru'
    }
}
