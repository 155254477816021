import { render, staticRenderFns } from "./BotTrigger.vue?vue&type=template&id=7f4037ca&scoped=true"
import script from "./BotTrigger.vue?vue&type=script&lang=ts"
export * from "./BotTrigger.vue?vue&type=script&lang=ts"
import style0 from "./BotTrigger.vue?vue&type=style&index=0&id=7f4037ca&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f4037ca",
  null
  
)

export default component.exports