<template>
    <v-app>
        <!--Диалоговые окна-->
        <v-dialog v-model="loading" fullscreen :transition="false">
            <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
                <v-layout justify-center align-center>
                    <v-progress-circular indeterminate color="main"> </v-progress-circular>
                </v-layout>
            </v-container>
        </v-dialog>
        <v-snackbar v-model="isError" left :timeout="-1" color="main">
            <span class="white--text">{{ error }}</span>
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="closeError"> Закрыть </v-btn>
            </template>
        </v-snackbar>
        <UniversalModal></UniversalModal>
        <RenameProjectDialog></RenameProjectDialog>
        <HintSidebar></HintSidebar>
        <AuthDialog></AuthDialog>
        <Notify></Notify>
        <!--Диалоговые окна-->
        <v-app-bar app color="mainDark" height="56px" elevation="3">
            <Logo />
            <HolidayBanner />

            <div>
                <Loading
                    v-if="!$auth.loading && $auth.isAuthenticated && !isProjectsLoading"
                    :isProjectPath="isProjectPath"
                    class="mr-6"
                ></Loading>
                <v-btn v-if="!$auth.loading && !$auth.isAuthenticated" text x-large @click="login" dark
                    >Войти</v-btn
                >
            </div>
            <ChatButton
                v-if="
                    project?.id === 'MPLBCVECY' ||
                        project?.id === 'FXK93110T' ||
                        project?.id === 'AGXTY3S5X' ||
                        project?.id === 'PBGXYGSEA'
                "
            />
            <UserMenu></UserMenu>
        </v-app-bar>
        <v-main v-if="!isProjectsLoading || isUnprotectedRoute">
            <GlobalNotification v-if="!isUnprotectedRoute" />
            <ConfirmationDialog />
            <router-view
                class="router-view"
                :class="{ 'bar-is-opened': notificationStore.BarIsActive }"
            ></router-view>
        </v-main>
        <v-container v-else fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
            <v-layout justify-center align-center>
                <v-progress-circular indeterminate color="main"> </v-progress-circular>
            </v-layout>
        </v-container>
        <Footer></Footer>
    </v-app>
</template>

<script>
import GlobalNotification from '@/components/GlobalNotification'
import HintSidebar from '@/components/HintSidebar'
import Notify from '@/components/Notify'
import Loading from '@/components/project/Loading'
import RenameProjectDialog from '@/components/project/dialogs/RenameProjectDialog'
import { useNotificationStore } from '@/store/stores/notification'
import websockets from '@/websockets'
import { mapStores } from 'pinia'
import qs from 'query-string'
import { mapState } from 'vuex'
import cfg from '../config'
import { getInstance } from './auth/authWrapper'
import ConfirmationDialog from './components/ConfirmationDialog.vue'
import Footer from './components/Footer.vue'
import HolidayBanner from './components/HolidayBanner.vue'
import Logo from './components/Logo.vue'
import UniversalModal from './components/UniversalModal/UniversalModal.vue'
import AuthDialog from './components/landing/AuthDialog.vue'
import UserMenu from './components/userMenu/UserMenu.vue'
import { unprotectedRoutesNames } from './router'
import { defineFavicon, reloadPageOnIdle } from './utils'
import ChatButton from '@/components/ChatButton.vue'
export default {
    name: 'App',

    components: {
        ChatButton,
        HintSidebar,
        Notify,
        RenameProjectDialog,
        Loading,
        Footer,
        GlobalNotification,
        AuthDialog,
        UserMenu,
        ConfirmationDialog,
        UniversalModal,
        Logo,
        HolidayBanner,
    },

    data: () => ({
        isNewYear: false,
        path: null,
        cfg,
    }),
    computed: {
        ...mapState(['loading', 'error', 'isProjectsLoading']),
        ...mapStores(useNotificationStore),
        error() {
            return this.$store.getters.error
        },
        isError() {
            return this.error ? true : false
        },

        isProjectsPath: function() {
            return this.path === '/projects'
        },
        isProjectPath() {
            if (!this.path) {
                return false
            }
            return this.path.split('/')[1] === 'project'
        },
        isUnprotectedRoute() {
            return unprotectedRoutesNames.includes(this.$route.name)
        },
        project () {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        }
    },
    //Add listener for billing balance
    watch: {
        $route(to, from) {
            this.path = to.path
            if (to.query.to) {
                const currentQuery = this.$router.currentRoute.query
                const toQuery = qs.parseUrl(to.query.to).query
                const path = qs.stringifyUrl({
                    url: to.query.to,
                    query: Object.assign({}, currentQuery, toQuery),
                })
                this.$router.push(path)
            }
            this.redirectIfNoProjectPermission()
        },
        isProjectsLoading() {
            this.redirectIfNoProjectPermission()
        },
    },
    methods: {
        async login() {
            this.$store.commit('setAuthDialogStartSection', 'login')
            this.$store.commit('setIsAuthDialog', true)
        },

        closeError() {
            this.$store.commit('setError', null)
        },
        setCachedItems() {
            const currentAddingConnectorCredentials = sessionStorage.getItem(
                'currentAddingConnectorCredentials'
            )
            if (
                currentAddingConnectorCredentials !== 'undefined' &&
                currentAddingConnectorCredentials !== null
            ) {
                this.$store.commit(
                    'project/setAddConectorCredentials',
                    JSON.parse(currentAddingConnectorCredentials)
                )
            }

            const currentAddingConnector = sessionStorage.getItem('currentAddingConnector')
            if (currentAddingConnector !== 'undefined' && currentAddingConnector !== null) {
                this.$store.commit('project/updateCurrentAddingConnector', JSON.parse(currentAddingConnector))
            }

            const addConectorSettings = sessionStorage.getItem('currentAddingConnectorSettings')
            if (addConectorSettings !== 'undefined' && addConectorSettings !== null) {
                this.$store.commit('project/setAddConectorSettings', JSON.parse(addConectorSettings))
            }

            const loggedAddingConnector = sessionStorage.getItem('loggedAddingConnector')
            if (loggedAddingConnector !== 'undefined' && loggedAddingConnector !== null) {
                this.$store.commit('project/setLoggedAddingConnector', JSON.parse(loggedAddingConnector))
            }
        },
        async setInitialData(authInstance) {
            this.$store.commit('setIsProjectsLoading', true)
            await this.$store.dispatch('project/setProjects')
            const token = authInstance.getTokenSilently()
            websockets.connect(token)
            console.log({ tokenForWS: token })
            websockets.socket.on('MESSAGE', payload => {
                this.$store.dispatch('callNotify', payload.message)
            })
            this.$store.commit('setIsProjectsLoading', false)
        },
        mountChat() {
            const liveChatScript = document.createElement('script')
            liveChatScript.setAttribute('src', 'https://livechatv2.chat2desk.com/packs/ie-11-support.js')
            document.head.appendChild(liveChatScript)
            window.chat24_token = 'dd84551d900366af8aa777bd44fa19b2'
            window.chat24_url = 'https://livechatv2.chat2desk.com'
            window.chat24_socket_url = 'wss://livechatv2.chat2desk.com/widget_ws_new'
            window.chat24_show_new_wysiwyg = 'true'
            window.chat24_static_files_domain = 'https://storage.chat2desk.com/'
            window.lang = 'ru'
            window
                .fetch(
                    ''.concat(window.chat24_url, '/packs/manifest.json?nocache=').concat(new Date().getTime())
                )
                .then(function(res) {
                    return res.json()
                })
                .then(function(data) {
                    var chat24 = document.createElement('script')
                    chat24.type = 'text/javascript'
                    chat24.async = true
                    chat24.src = ''.concat(window.chat24_url).concat(data['application.js'])
                    document.body.appendChild(chat24)
                })
        },
        handleInvite() {
            setTimeout(() => {
                const authInstance = getInstance()
                if (this.$router.currentRoute.query.action === 'invite') {
                    if (authInstance.isAuthenticated) {
                        this.$router.push(this.$route.path)
                    } else {
                        //Если пользователь с таким email существует, отображаем login
                        if (this.$router.currentRoute.query.user_exists === 'true') {
                            this.$auth.loginWithRedirect()
                            //Если пользователь с таким email не существует, отображаем signup
                        } else {
                            this.$auth.loginWithRedirect({ screen_hint: 'signup' })
                        }
                    }
                }
            }, 500)
        },

        //Редирект на страницу проектов, если нет доступа к проекту
        redirectIfNoProjectPermission() {
            if (this.isProjectPath && !this.isProjectsLoading) {
                const project = this.$store.getters.projectById(this.$router.currentRoute.params?.id)
                if (!project) {
                    this.$store.dispatch('callNotify', 'Нет прав')
                    this.$router.push('/projects')
                }
            }
        },
    },
    created: async function() {
        defineFavicon()
        const authInstance = getInstance()
        authInstance.$watch('loading', async loading => {
            if (!loading) {
                this.path = this.$router.currentRoute.path
                this.handleInvite()
                if (authInstance.isAuthenticated) {
                    this.setInitialData(authInstance)
                }
            }
        })
        authInstance.$watch('isAuthenticated', async isAuthenticated => {
            if (!authInstance.loading) {
                if (isAuthenticated) {
                    const nextPath = qs.stringifyUrl({
                        url: '/projects',
                        query: this.$router.currentRoute.query,
                    })
                    this.$router.push(nextPath)
                    this.setInitialData(authInstance)
                } else {
                    const path = qs.stringifyUrl({
                        url: '/',
                        query: this.$router.currentRoute.query,
                    })
                    this.$router.push(path)
                }
            }
        })
        this.setCachedItems()
    },
    mounted() {
        this.mountChat()
        reloadPageOnIdle(cfg.idleReloadTimeout)
    },
}
</script>

<style lang="sass">
@import '@/vars.sass'
@import '@/assets/styles/GCB2.sass'
a
    text-decoration: none
h1.dashboard
    font-weight: normal !important
    font-size: 36px !important
h2.dashboard
    font-weight: normal !important
    font-size: 26px !important
h3.dashboard
    font-weight: normal !important
    font-size: 20px !important
header
    z-index: 102 !important
.col
    &.no-padding
        padding: 0
.sidebar-wrapper
    display: flex
.content
    max-width: 1016px
    flex-grow: 1
    &.sidebar-is-open
        margin-left: 25px
        margin-right: 25px
.v-menu__content
    z-index: 10000 !important
.content-wrapper
    flex-grow: 1
    display: flex
    justify-content: center
    margin-bottom: 136px
    margin-top: 80px
.v-application .headline
    font-size: 20px !important
.nav-btn
    color: $nav-btn-text
    padding-left: 20px
    height: 56px
    width: 180px
    padding-right: 20px
    font-size: 24px
    border-bottom: 3px solid transparent
.nav-btn:focus
    outline: 0
.nav-btn:hover
    border-bottom: 3px solid $main
    background-color: $main-dark-hover
.nav-btn-active
    color: $main
    background-color: $main-dark-hover
    border-bottom: 3px solid $main
.btn-w-icon
    padding-left: 8px !important
.mr-10px
    margin-right: 10px
.mr-5px
    margin-right: 5px
.ml-15px
    margin-left: 15px
.visibility-hidden
    visibility: hidden
.v-dialog
    border-radius: 0
.text-btn:hover
    color: black
.text-btn-wrapper:hover
    background-color: $light-gray-5
    cursor: pointer
.text-btn-wrapper
    font-size: 14px !important
    padding: 0 !important
    &.disabled
        opacity: 0.5
.router-view
    &.bar-is-opened
        padding-top: 40px
.thin-btn
    border: 1px solid #0000001F
    padding-left: 5px !important
.outlined-btn
    border: 1px solid #0000001F
    .v-btn__loader > .v-progress-circular
        color: $main !important
.error--text span
    color: #ff5252 !important
    caret-color: #ff5252 !important
.v-btn__loader > .v-progress-circular
    color: white !important
.error--text div
    color: #ff5252 !important
    caret-color: #ff5252 !important
.v-list-item--disabled .v-list-item__content .v-list-item__title
    color: rgba(0, 0, 0, 0.38) !important
.v-list-item--disabled.text-btn-wrapper  > span
    color: rgba(0, 0, 0, 0.38) !important

h1, h2, h3, h4, h5, h6, p, span, td, div:not(.v-alert__wrapper, .v-alert__content, .v-btn__content, .v-input__slot, .v-tab--active, .v-tab, .v-tabs, .v-tabs-bar__content, .v-tabs-slider-wrapper, .v-slide-group__wrapper, .v-input__control, .v-input--selection-controls__input)
    color: $text-color
input
    color: $text-color !important
.v-list-item--disabled
    color: rgba(0, 0, 0, 0.38) !important
.v-application .error--text
    color: #ff5252 !important
    caret-color: #ff5252 !important
thead
    background-color: #0000000b
td
    border-bottom: thin solid rgba(0, 0, 0, 0.12)
tr

    font-size: 16px !important
th
    font-weight: 400
    font-size: 16px !important
    cursor: default
code
    background-color: $light-gray !important
    color: black !important
td.text-end
    padding-right: 4px!important
.v-input__prepend-inner
    padding-right: 12px !important
    padding-left: 4px
.footer
    display: flex
    justify-content: center
    align-items: center

    &-content
        width: 1016px
.v-tour__target--highlighted
    z-index: 0
    box-shadow: 0 0 0 0 #2A3B5199 !important
.v-tour__target--highlighted:after
    z-index: -1
    content: ''
    position: absolute
    border-radius: 100%
    width: 100px
    height: 100px
    box-shadow: 0px 0px 0px 666666px #2A3B5199 !important
    sbox-shadow: 0 0 0 666666px rgba(42,59,81,.6)!important
    pointer-events: none
.v-step__arrow
    display: none

.v-tour__target--highlighted.custom-highlight:after
    z-index: -1
    content: ''
    position: absolute
    border-radius: 100%
    width: 210px !important
    height: 210px !important
    transform: translate(-30px, -50px)
    box-shadow: 0px 0px 0px 666666px #2A3B5199 !important
    sbox-shadow: 0 0 0 666666px rgba(42,59,81,.6)!important
    pointer-events: none

.v-window
    overflow: visible !important
    
.gcb-returnability-subheader-highlight-text
    font-weight: bold
.v-slider__thumb-label span
    color: #fff !important
.text-highlight
    background-color: $main-light !important
    border-radius: 3px !important
.sidebar-input
    max-width: 300px
.sidebar-title
    font-size: 20px
.v-input--reverse .v-input__slot
    flex-direction: row-reverse
    justify-content: flex-end
.svg-icon:hover
    filter: invert(44%) sepia(82%) saturate(2059%) hue-rotate(198deg) brightness(103%) contrast(101%)
.v-text-field--filled > .v-input__control > .v-input__slot
    background: #2A3B510B !important
.lightgray-border
    border: 1px solid #0000001F
.font-size-14px
    font-size: 14px
.theme--light.v-btn
    color: $text-color
.global-alert-tooltip
    opacity: 1 !important
    margin: 0 !important
input, textarea
    color: $text-color
.select-input input
    cursor: pointer
button:not(.white--text) .v-btn__loader svg
    color: $main
button.v-btn--outlined.white--text .v-btn__loader svg
    color: $main
.add-btn
    border: 1px solid $main
    border-radius: 4px
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    &:hover
      background-color: $main-light

.v-tooltip__content
    background-color: #2A3B51DE !important
    white-space: break-spaces !important
    span
        color: white !important

.add-btn
    border: 1px solid $main
    border-radius: 4px
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    &.active
        background-color: $main-light
</style>
