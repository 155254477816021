
import { PropType, computed, defineComponent, onBeforeMount, ref, onMounted } from 'vue'

import * as _ from 'lodash'
import messages from '@dataheroes/messages'
import TextField from '../../../../inputs/TextField.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
import TextInputGroup from '../../../../ui/TextInputGroup.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { TextField, BaseInputOutlined, AdvancedMessageEditor, TextInputGroup },
    setup(props, { emit }) {
        const answersMessageVars = [{ id: 'ссылка-на-отзыв', name: 'ссылка-на-отзыв' }]
        const update = (reaction: string, val: string, key: string) => {
            emit(
                'input',
                Object.assign({}, props.value, {
                    [key]: Object.assign({}, props.value[key], { [reaction]: val }),
                })
            )
        }

        return { update, answersMessageVars }
    },
})
