import { getInstance } from '@root/src/auth'
import { ApiWrapper } from '../../types/main'
import { apiCall, http } from '../http'
import cfg from '@root/config'
import { getError } from '../../utils'

interface LoginParams {
    connectorId: string
    token: string
}
interface GetUsersParams {
    connectorId: string
    token: string
}
interface UpdateCallHistoryParams {
    connectorId: string
}
export async function login({ connectorId, token }: LoginParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/beeline/login`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { connectorId, token } }))
    return res
}
export async function getUsers(connectorId: string): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/beeline/getUsers`
    const res = apiCall<any>(() => http.get(url.toString(), { searchParams: { connectorId } }))
    return res
}
export async function updateCallHistory({ connectorId }: UpdateCallHistoryParams): Promise<ApiWrapper<any>> {
    const url = `ipTelephony/beeline/updateHistory`
    const res = apiCall<any>(() => http.post(url.toString(), { json: { connectorId } }))
    return res
}
