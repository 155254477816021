
import {
    computed,
    defineComponent,
    ref,
    shallowRef,
    watch,
    PropType,
    onMounted,
    getCurrentInstance,
} from 'vue'
import * as _ from 'lodash'
import api from '../../api/api'
import { useProject } from '../../hooks/useProject'
import SpinnerLoader from '../SpinnerLoader.vue'
import { useDropZone } from '../../hooks/useDropzone'
interface UploadedFile {
    src: string
    originalName: string
    mimeType: string
}
export default defineComponent({
    components: { SpinnerLoader },
    props: {
        value: { type: Object as PropType<UploadedFile>, required: true },
        maxFileSize: { type: Number },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { project } = useProject()
        const fileUploader = ref<any>(null)
        const allowedMimeTypes = ['image/png', 'image/gif', 'image/jpeg', 'application/pdf']
        const update = (v: any | null) => {
            emit('input', v)
        }
        const loading = ref(false)

        const showImagePreview = computed(() => {
            const imageExtensions = ['.png', '.jpg', '.gif', '.jpeg']
            return props.value && imageExtensions.some(ext => props.value?.src?.endsWith(ext))
        })
        const showDocPreview = computed(() => {
            const docExtensions = ['.pdf']
            return props.value && docExtensions.some(ext => props.value?.src?.endsWith(ext))
        })

        const openUploadWindow = () => {
            const fileInput = root.$refs['fileInput'] as HTMLInputElement
            fileInput.click()
        }
        const clearFile = () => {
            const fileInput = root.$refs['fileInput'] as HTMLInputElement
            fileInput.value = ''
            update({})
        }
        const onInputChange = (e: Event) => {
            const target = e.target as HTMLInputElement
            const files = target.files!
            uploadFile(files)
        }
        const uploadFile = async (files: File[] | FileList | null) => {
            if (!files) return
            loading.value = true
            const file = _.first<File>(files)
            if (!allowedMimeTypes.includes(file?.type!)) {
                root.$store.dispatch('callNotify', 'Не верный формат файла')
                loading.value = false
                return
            }

            if (props.maxFileSize && file && file?.size > props.maxFileSize) {
                root.$store.dispatch('callNotify', 'Превышен максимальный размер файла')
                clearFile()
                loading.value = false
                return
            }
            const { data, error } = await api.file.uploadMedia({ projectId: project.value.id, file: file! })
            if (error) {
                root.$store.dispatch('callNotify', 'Произошла ошибка при загрузке файла')
                loading.value = false
                return
            }
            update(data!.url)
            loading.value = false
        }

        const { isOverDropZone } = useDropZone(fileUploader, {
            onDrop: uploadFile,
        })
        return {
            update,
            uploadFile,
            openUploadWindow,
            clearFile,
            showImagePreview,
            showDocPreview,
            loading,
            isOverDropZone,
            fileUploader,
            onInputChange,
            allowedMimeTypes,
        }
    },
})
